<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-25 22:26:11
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-28 16:14:06
-->
<template>
  <div class="String_cargo">
    <!-- <h2 align="center">{{ title }}</h2> -->
    <!-- <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
      <a-row :gutter="0">
        <a-row :md="24" :xl="24" :xxl="24" :sm="24" align="center">
          <a-col  align="center">
            <a-input style="text-algin: center;width: 90%" v-model="searchData.uniqueCharacter" placeholder="输入防串货唯一标识码" />
          </a-col>
        </a-row>
        <a-row :md="24" :xl="24" :xxl="24" :sm="24" >
          <a-col  align="center">
            <a-button style="text-algin: center;width: 80%;top: 50px" type="primary" @click="onSearch()">查询</a-button>
          </a-col>
        </a-row>
      </a-row>
    </a-form-model> -->
    <div class="content">
      <h3 style="text-align: center"><span>{{title}}</span></h3>
      <div class="cargo">
        <a-input style="width: 90%" v-model="searchData.uniqueCharacter" placeholder="请输入防串货唯一标识码"></a-input>
      </div>
      <div class="btn" style="margin-top: 20px; text-align: center;">
        <a-button style="margin-left: .3rem;width: 88%" type="primary" @click="onSearch()">查询</a-button>
      </div>
    </div>
  </div>
</template>

<script>

import {query} from '@/views/wap/api/WapApi';

export default {

  data() {
    return {
      title: '防串货查询',
      searchData: {
        'uniqueCharacter' : 'qwertyuiop',
      }, // 搜索条件
    }
  },

  methods:{
    /**
     * code ： 是的撒的
     */
    onSearch() {
      query (
        this.searchData
      ).then(res => {
          if(res.code===200){
            this.$router.push({
              name: 'info',
              params:{
                id:res.body.id
              }
            })
          }
      })
    },
  }
}
</script>

<style scoped>
  .cargo{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .String_cargo {
    position: relative;
  }
  .content {
    position: absolute;
    width: 100%;
    top: 120px
  }
</style>
